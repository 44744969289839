<template>
  <v-form ref="editOrganisations" v-model="editOrganisation" lazy-validation>
    <v-text-field class="pt-6" v-model="editOrganisationForm.name" label="Name" :rules="[() => !!editOrganisationForm.name || 'Dieses Feld wird benötigt!']" required></v-text-field>
    <v-text-field v-model="editOrganisationForm.testSiteNumber" label="Teststellennummer" :rules="[() => !!editOrganisationForm.testSiteNumber || 'Dieses Feld wird benötigt!']" required></v-text-field>
    <v-text-field v-model="editOrganisationForm.streetName" label="Straße" :rules="[() => !!editOrganisationForm.streetName || 'Dieses Feld wird benötigt!']" required></v-text-field>
    <v-text-field v-model="editOrganisationForm.houseNumber" label="Hausnummer" :rules="[() => !!editOrganisationForm.houseNumber || 'Dieses Feld wird benötigt!']" required></v-text-field>
    <v-text-field v-model="editOrganisationForm.cityName" label="Stadt" :rules="[() => !!editOrganisationForm.cityName || 'Dieses Feld wird benötigt!']" required></v-text-field>
    <v-text-field v-model="editOrganisationForm.zipCode" label="Postleitzahl" :rules="[() => !!editOrganisationForm.zipCode || 'Dieses Feld wird benötigt!']" required></v-text-field>
    <v-text-field v-model="editOrganisationForm.mailHealthDepartment" label="E-Mail Gesundheitsamt" :rules="[() => !!editOrganisationForm.mailHealthDepartment || 'Dieses Feld wird benötigt!']" required></v-text-field>
    <v-text-field v-model="editOrganisationForm.mailSelectedMedic" label="Ansprechpartner Unternehmen"></v-text-field>
    <v-textarea v-model="editOrganisationForm.mailAdditionalText" label="E-Mail Text"></v-textarea>
    <v-checkbox v-model="editOrganisationForm.selfManaged" label="Selbst organisiert" :disabled="editOrganisationForm.testPoint"></v-checkbox>
    <v-checkbox v-model="editOrganisationForm.testPoint" label="Testpoint" :disabled="editOrganisationForm.selfManaged"></v-checkbox>
    <v-checkbox v-model="editOrganisationForm.customUrl" label="Öffentliche Anmeldung"></v-checkbox>
    <v-checkbox v-model="editOrganisationForm.vaccine" label="Terminbuchung"></v-checkbox>
    <v-checkbox v-model="editOrganisationForm.pharmacy" label="Apotheke"></v-checkbox>
    <v-btn :loading="organisationLoading" :disabled="!editOrganisation" color="primary light" @click="saveOrganisation" class="float-right">
      Speichern
    </v-btn>
    <v-snackbar v-model="snackbar" timeout="3000">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">
          Schließen
        </v-btn>
      </template>
    </v-snackbar>
  </v-form>
</template>

<script>
export default {
  props: { organisation: Object },
  data() {
    return {
      editOrganisationForm: {},
      editOrganisation: true,
      organisationLoading: false,
      snackbar: false,
      snackbarText: "",
    };
  },

  created() {
    this.editOrganisationForm.name = this.organisation.name;
    this.editOrganisationForm.streetName = this.organisation.streetName;
    this.editOrganisationForm.cityName = this.organisation.cityName;
    this.editOrganisationForm.houseNumber = this.organisation.houseNumber;
    this.editOrganisationForm.zipCode = this.organisation.zipCode;
    this.editOrganisationForm.mailHealthDepartment = this.organisation.mailHealthDepartment;
    this.editOrganisationForm.mailSelectedMedic = this.organisation.mailSelectedMedic;
    this.editOrganisationForm.selfManaged = this.organisation.selfManaged;
    this.editOrganisationForm.customUrl = this.organisation.customUrl;
    this.editOrganisationForm.testSiteNumber = this.organisation.testSiteNumber;
    this.editOrganisationForm.testPoint = this.organisation.testPoint;
    this.editOrganisationForm.vaccine = this.organisation.vaccine;
    this.editOrganisationForm.pharmacy = this.organisation.pharmacy;
    this.editOrganisationForm.mailAdditionalText = this.organisation.mailText;
  },

  methods: {
    saveOrganisation() {
      this.organisationLoading = true;

      this.$http({
        method: "patch",
        url: "/organisation/" + this.$route.params.id,
        data: {
          name: this.editOrganisationForm.name,
          streetName: this.editOrganisationForm.streetName,
          cityName: this.editOrganisationForm.cityName,
          houseNumber: this.editOrganisationForm.houseNumber,
          zipCode: this.editOrganisationForm.zipCode,
          mailHealthDepartment: this.editOrganisationForm.mailHealthDepartment,
          mailSelectedMedic: this.editOrganisationForm.mailSelectedMedic,
          selfManaged: this.editOrganisationForm.selfManaged,
          customUrl: this.editOrganisationForm.customUrl,
          testSiteNumber: this.editOrganisationForm.testSiteNumber,
          testPoint: this.editOrganisationForm.testPoint,
          vaccine: this.editOrganisationForm.vaccine,
          pharmacy: this.editOrganisationForm.pharmacy,
          mailText: this.editOrganisationForm.mailAdditionalText,
        },
      })
        .then((res) => {
          this.$emit("updated", res.data);
          this.organisationLoading = false;
        })
        .catch((err) => {
          this.snackbarText = "Fehler bei Abfrage";
          this.snackbar = true;
          this.organisationLoading = false;
          console.log("AXIOS ERROR: ", err);
        });
    },
  },
};
</script>
