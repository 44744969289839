<template>
  <v-container>
    <div class="d-flex justify-space-between my-5">
      <div class="v-text-field text-h4">Firmenübersicht</div>
      <div class="v-text-field text-h6">{{ organisation.name }}</div>
    </div>
    <v-row>
      <v-col md="4">
        <v-card>
          <v-expansion-panels v-model="sidePanel" focusable accordion>
            <v-expansion-panel>
              <v-expansion-panel-header><h4>Unternehmen bearbeiten</h4></v-expansion-panel-header>
              <v-expansion-panel-content>
                <edit-form v-if="organisation.name" :organisation="organisation" v-on:updated="organisationUpdated"></edit-form>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel v-if="organisation.selfManaged">
              <v-expansion-panel-header><h4>Manager bearbeiten</h4></v-expansion-panel-header>
              <manager v-if="organisation.name" :organisation="organisation"></manager>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header><h4>Neue Testperson anlegen</h4></v-expansion-panel-header>
              <v-expansion-panel-content>
                <new-worker-form v-if="organisation.name" :organisationId="organisation.id" v-on:newworker="workerCreated"></new-worker-form>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel v-if="organisation.customUrl">
              <v-expansion-panel-header> <h4>Anmelde Link</h4></v-expansion-panel-header>
              <v-expansion-panel-content>
                <create-url v-if="organisation.name" :organisation="organisation" v-on:newworker="urlCreated"></create-url>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </v-col>
      <v-col>
        <v-row>
          <v-col>
            <v-card>
              <v-card-title>
                Übersicht
                <v-spacer></v-spacer>
                <v-btn color="primary" dark class="mb-2" @click="linkToImportView">
                  Testpersonen importieren
                </v-btn>
              </v-card-title>
              <v-tabs v-model="tab" background-color="primary" dark>
                <v-tabs-slider color="yellow"></v-tabs-slider>
                <v-tab>Testpersonen</v-tab>
                <v-tab v-if="!organisation.testPoint">Tests</v-tab>
                <v-tab v-if="organisation.testPoint || organisation.vaccine">Termine</v-tab>
              </v-tabs>
              <v-tabs-items v-model="tab">
                <v-tab-item>
                  <v-card-title>
                    <v-text-field v-model="workerSearch" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
                  </v-card-title>
                  <v-data-table :headers="workerHeaders" :items="organisation.workers" :items-per-page="15" :search="workerSearch" :loading="loading" sort-by="id" :sort-asc="true" class="elevation-1">
                    <template v-slot:item.actions="{ item }">
                      <v-icon small class="mr-2" color="primary" @click="editWorker(item)">
                        mdi-pencil
                      </v-icon>
                      <v-icon small @click="deleteWorker(item)" color="red">
                        mdi-delete
                      </v-icon>
                    </template>
                  </v-data-table>
                </v-tab-item>
                <v-tab-item v-if="!organisation.testPoint">
                  <v-card-title>
                    <v-text-field v-model="testSearch" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
                  </v-card-title>
                  <v-data-table :headers="testHeaders" :items="organisation.tests" :items-per-page="15" :search="testSearch" :loading="loading" sort-by="id" :sort-desc="true" class="elevation-1">
                    <template v-slot:item.dateCheckedIn="{ item }">{{ displayDate(item.dateCheckedIn) }} </template>

                    <template v-slot:item.testPerson="{ item }">
                      {{ getTestPersonName(item) }}
                    </template>
                  </v-data-table>
                </v-tab-item>
                <v-tab-item v-if="organisation.testPoint || organisation.vaccine">
                  <v-card-title>
                    <v-dialog v-model="dialogAppointment" max-width="600px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                          Neue Termine
                        </v-btn>
                      </template>
                      <new-appointment :organisationId="organisation.id" v-on:newappointment="appointmentCreated"></new-appointment>
                    </v-dialog>
                    <v-spacer></v-spacer>
                    <v-text-field v-model="appointmentSearch" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
                  </v-card-title>
                  <v-data-table :headers="appointmentHeaders" :items="organisation.appointments" :items-per-page="15" :search="appointmentSearch" :loading="loading" sort-by="id" :sort-desc="true" class="elevation-1">
                    <template v-slot:item.appointmentDate="{ item }">
                      {{ displayDateWithTime(item.appointmentDate) }}
                    </template>

                    <template v-slot:item.actions="{ item }">
                      <v-icon small class="mr-2" color="primary" @click="editAppointment(item)">
                        mdi-pencil
                      </v-icon>
                      <v-icon small @click="deleteAppointment(item)" color="red">
                        mdi-delete
                      </v-icon>
                    </template>
                  </v-data-table>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogDeleteWorker" max-width="600px">
      <v-card>
        <v-card-title class="headline"><div>Möchten Sie die Testperson löschen?</div></v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDeleteWorker = false">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="deleteWorkerConfirm">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogEditWorker" max-width="600px">
      <edit-worker :worker="organisation.workers[workerIndex]" v-on:editworker="workerEdited"></edit-worker>
    </v-dialog>
    <v-dialog v-model="dialogDeleteAppointment" max-width="600px">
      <v-card>
        <v-card-title class="headline"><div>Möchten Sie den Termin löschen?</div></v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDeleteAppointment = false">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="deleteAppointmentConfirm">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogEditAppointment" max-width="600px">
      <edit-appointment :appointment="organisation.appointments[appointmentIndex]" v-on:editappointment="appointmentEdited"></edit-appointment>
    </v-dialog>
    <v-snackbar v-model="snackbar" timeout="3000">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">
          Schließen
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import dayjs from "dayjs";

import EditForm from "../components/admin_organisation/Edit.vue";
import NewWorkerForm from "../components/worker/New.vue";
import Manager from "../components/admin_organisation/Manager.vue";
import EditWorker from "../components/worker/Edit.vue";
import CreateUrl from "../components/admin_organisation/CreateURL.vue";
import NewAppointment from "../components/appointment/New.vue";
import EditAppointment from "../components/appointment/Edit.vue";
// import LineChart from "../components/charts/LineChart.js";
import lodash from "lodash";

export default {
  components: {
    EditForm,
    NewWorkerForm,
    Manager,
    EditWorker,
    CreateUrl,
    NewAppointment,
    EditAppointment,
    // LineChart,
  },

  data() {
    return {
      chartData: [0, 1, 5, 2, 3, 6, 2],
      tab: null,
      workerSearch: "",
      testSearch: "",
      appointmentSearch: "",
      loading: false,
      errored: false,
      sidePanel: 0,
      snackbar: false,
      snackbarText: "",
      workerHeaders: [
        {
          text: "ID",
          align: "start",
          value: "id",
        },
        { text: "Name", value: "fullName" },
        { text: "E-Mail", value: "emailAddress" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      testHeaders: [
        {
          text: "ID",
          align: "start",
          value: "id",
        },
        { text: "Identifier", value: "uniqueIdentifierTest" },
        { text: "Testperson", value: "testPerson" },
        { text: "Testdatum", value: "dateCheckedIn" },
      ],
      appointmentHeaders: [
        {
          text: "ID",
          align: "start",
          value: "id",
        },
        { text: "Datum", value: "appointmentDate" },
        { text: "Slots gesammt", value: "amountOfPeople" },
        { text: "Slots gebucht", value: "bookedTests" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      organisation: { workers: [] },
      testCount: 0,
      workerIndex: 0,
      workerDelete: {},
      appointmentIndex: 0,
      appointmentDelete: {},
      dialogDeleteWorker: false,
      dialogEditWorker: false,
      dialogAppointment: false,
      dialogDeleteAppointment: false,
      dialogEditAppointment: false,
    };
  },

  mounted() {
    this.loading = true;
    this.$http({
      method: "get",
      url: "/organisation/" + this.$route.params.id,
    })
      .then((response) => {
        this.organisation = response.data;
        this.loading = false;
      })
      .catch((err) => {
        this.errored = true;
        this.loading = false;
        console.log("AXIOS ERROR: ", err);
      });
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
  },

  methods: {
    linkToImportView() {
      this.$router.push("/organisation/" + this.organisation.id + "/import");
    },

    urlCreated() {
      this.snackbarText = "Custom URL wurde erfolgreich aktualisiert";
      this.snackbar = true;
    },

    organisationUpdated(organisation) {
      this.organisation.customUrl = organisation.customUrl;
      this.organisation.hash = organisation.hash ? organisation.hash : "";
      this.snackbarText = "Unternehmen wurde erfolgreich aktualisiert";
      this.snackbar = true;
    },

    workerCreated(worker) {
      this.organisation.workers.push(worker);
      this.snackbarText = "Testperson wurde erfolgreich angelegt";
      this.snackbar = true;
    },

    displayDate(timestamp) {
      return dayjs(timestamp).format("DD.MM.YYYY");
    },

    displayDateWithTime(timestamp) {
      return dayjs(timestamp).format("DD.MM.YYYY - HH:mm");
    },

    editWorker(item) {
      this.workerIndex = this.organisation.workers.indexOf(item);
      this.dialogEditWorker = true;
    },

    workerEdited() {
      this.dialogEditWorker = false;
      this.snackbarText = "Testperson wurde erfolgreich geändert";
      this.snackbar = true;
    },

    deleteWorker(item) {
      this.workerIndex = this.organisation.workers.indexOf(item);
      this.workerDelete = item;
      this.dialogDeleteWorker = true;
    },

    deleteWorkerConfirm() {
      this.$http({
        method: "delete",
        url: "/worker",
        data: {
          workerId: this.workerDelete.id,
        },
      })
        .then(() => {
          this.snackbarText = "Testperson erfolgreich gelöscht";
          this.snackbar = true;
          this.organisation.workers.splice(this.workerIndex, 1);
        })
        .catch((err) => {
          this.snackbarText = "Fehler bei Abfrage";
          this.snackbar = true;
          console.log("AXIOS ERROR: ", err);
        });

      this.dialogDeleteWorker = false;
    },
    appointmentCreated(appointments) {
      this.dialogAppointment = false;
      appointments.forEach((appointment) => this.organisation.appointments.push(appointment));
      this.snackbarText = "Termine wurden erfolgreich angelegt";
      this.snackbar = true;
    },

    editAppointment(item) {
      this.appointmentIndex = this.organisation.appointments.indexOf(item);
      this.dialogEditAppointment = true;
    },

    appointmentEdited() {
      this.dialogEditAppointment = false;
      this.snackbarText = "Termin wurde erfolgreich geändert";
      this.snackbar = true;
    },

    deleteAppointment(item) {
      this.appointmentIndex = this.organisation.appointments.indexOf(item);
      this.appointmentDelete = item;
      this.dialogDeleteAppointment = true;
    },

    deleteAppointmentConfirm() {
      this.$http({
        method: "delete",
        url: "/appointment/" + this.appointmentDelete.id,
      })
        .then(() => {
          this.snackbarText = "Termin erfolgreich gelöscht";
          this.snackbar = true;
          this.organisation.appointments.splice(this.appointmentIndex, 1);
        })
        .catch((err) => {
          this.snackbarText = "Fehler bei Abfrage";
          this.snackbar = true;
          console.log("AXIOS ERROR: ", err);
        });

      this.dialogDeleteAppointment = false;
    },

    getTestPersonName(item) {
      var worker = lodash.find(this.organisation.workers, { id: item.worker });
      if (!worker) {
        return "Gelöscht";
      }
      return worker.fullName;
    },
  },
};
</script>
