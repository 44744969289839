<template>
  <v-expansion-panel-content>
    <v-form v-model="managerForm">
      <v-row>
        <v-col sm="9">
          <v-text-field
            class="pt-5"
            label="Benutzer Id"
            v-model="managerFormData.id"
            :rules="[() => !!managerFormData.id || 'Dieses Feld wird benötigt!']"
          ></v-text-field>
        </v-col>
        <v-col sm="3">
          <v-btn
            :disabled="!managerForm"
            :loading="managerLoading"
            class="mt-7"
            small
            color="primary light"
            @click="saveManager"
          >
            <v-icon>
              mdi-plus
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-divider></v-divider>
    <v-list>
      <v-list-item v-for="manager in managers" :key="manager.id">
        <v-list-item-icon>
          <v-icon>mdi-account</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="">{{ manager.fullName }}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn @click="deleteManager(manager.id)" icon>
            <v-icon color="red">mdi-delete</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <v-snackbar v-model="snackbar" timeout="3000">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">
          Schließen
        </v-btn>
      </template>
    </v-snackbar>
  </v-expansion-panel-content>
</template>

<script>
export default {
  props: {
    organisation: Object,
  },

  data() {
    return {
      snackbar: false,
      snackbarText: "",
      managers: [],
      managerForm: false,
      managerFormData: {},
      managerLoading: false,
    };
  },

  created() {
    this.managers = this.organisation.managers;
  },

  methods: {
    saveManager() {
      this.managerLoading = true;

      this.$http({
        method: "put",
        url: "organisation/add-manager",
        data: {
          managerId: this.managerFormData.id,
          organisationId: this.organisation.id,
        },
      })
        .then((res) => {
          this.snackbarText = "Manager wurder erfolgreich hinzugefügt";
          this.snackbar = true;
          this.managers = res.data;
          this.managerLoading = false;
        })
        .catch((err) => {
          this.snackbarText = "Fehler bei Abfrage";
          this.snackbar = true;
          this.managerLoading = false;
          console.log("AXIOS ERROR: ", err);
        });
    },

    deleteManager(managerId) {
      this.$http({
        method: "put",
        url: "organisation/remove-manager",
        data: {
          managerId: managerId,
          organisationId: this.organisation.id,
        },
      })
        .then((res) => {
          this.snackbarText = "Manager wurder erfolgreich entfernt";
          this.snackbar = true;
          this.managers = res.data;
        })
        .catch((err) => {
          this.snackbarText = "Fehler bei Abfrage";
          this.snackbar = true;
          console.log("AXIOS ERROR: ", err);
        });
    },
  },
};
</script>
