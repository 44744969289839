<template>
  <div>
    <div class="d-flex flex-row align-center">
      <v-text-field ref="textToCopy" v-model="customURL" label="Anmeldelink" readonly></v-text-field>
      <v-btn @click="copyText" icon x-small color="black"><v-icon>mdi-content-copy</v-icon></v-btn>
    </div>
    <v-img :src="qrCode" width="100%" />
    <a download="qrcode.png" :href="qrCode">Download QR-Code</a>
  </div>
</template>

<script>
import QRCode from "qrcode";

export default {
  props: { organisation: Object },
  data() {
    return {
      snackbar: false,
      snackbarText: "",
      qrCode: null,
    };
  },

  computed: {
    customURL: function() {
      return window.location.origin + "/#/registration/" + this.organisation.hash;
    },
  },

  // ADD QR CODE
  mounted() {
    this.$nextTick(() => {
      const generateQR = async (text) => {
        try {
          return await QRCode.toDataURL(text);
        } catch (err) {
          console.error(err);
        }
      };

      (async () => {
        this.qrCode = await generateQR(window.location.origin + "/#/registration/" + this.organisation.hash);
      })();
    });
  },

  methods: {
    copyText() {
      let textToCopy = this.$refs.textToCopy.$el.querySelector("input");
      textToCopy.select();
      document.execCommand("copy");
    },
  },
};
</script>
