<template>
  <v-card>
    <v-card-title>
      <span class="headline">Termin bearbeiten</span>
    </v-card-title>

    <v-form ref="editAppointmentForm" v-model="editAppointmentForm" lazy-validation>
      <v-card-text>
        <v-row class="mt-n8">
          <v-col>
            <v-text-field v-model="appointmentDate" label="Termin" required disabled readonly></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="editAppointmentFormData.amountOfPeople"
              label="Anzahl Personen pro 10 Min"
              required
              :rules="[() => !!editAppointmentFormData.amountOfPeople || 'Dieses Feld wird benötigt!']"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-checkbox v-model="editAppointmentFormData.antigen" label="Antigen"></v-checkbox>
          </v-col>
          <v-col>
            <v-checkbox v-model="editAppointmentFormData.pcr" label="PCR"></v-checkbox>
          </v-col>
          <v-col>
            <v-checkbox v-model="editAppointmentFormData.igm" label="IGM"></v-checkbox>
          </v-col>
          <v-col>
            <v-checkbox v-model="editAppointmentFormData.igg" label="IGG"></v-checkbox>
          </v-col>
          <v-col>
            <v-checkbox v-model="editAppointmentFormData.iggs" label="IGGs"></v-checkbox>
          </v-col>
          <v-col>
            <v-checkbox v-model="editAppointmentFormData.paidantigen" label="Antigen paid"></v-checkbox>
          </v-col>
          <v-col>
            <v-checkbox v-model="editAppointmentFormData.vac" label="Impfung"></v-checkbox>
          </v-col>
          <v-col>
            <v-checkbox v-model="editAppointmentFormData.pcrintern" label="PCR - Intern"></v-checkbox>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :disabled="!editAppointmentForm" :loading="loading" color="blue darken-1" text @click="saveAppointment">
          Speichern
        </v-btn>
      </v-card-actions>
      <v-snackbar v-model="snackbar" timeout="3000">
        {{ snackbarText }}

        <template v-slot:action="{ attrs }">
          <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">
            Schließen
          </v-btn>
        </template>
      </v-snackbar>
    </v-form>
  </v-card>
</template>

<script>
import dayjs from "dayjs";

export default {
  props: { appointment: Object },
  data() {
    return {
      snackbar: false,
      snackbarText: "",
      editAppointmentForm: false,
      editAppointmentFormData: {},
      appointmentDatepicker: false,
      loading: false,
      appointmentDate: "",
    };
  },

  created() {
    this.editAppointmentFormData = this.appointment;
    this.appointmentDate = dayjs(this.appointment.appointmentDate).format("DD.MM.YYYY - HH:mm");
  },

  watch: {
    appointment: function(val) {
      if (val) {
        this.editAppointmentFormData = this.appointment;
        this.appointmentDate = dayjs(this.appointment.appointmentDate).format("DD.MM.YYYY - HH:mm");
      }
    },
  },

  methods: {
    saveAppointment() {
      if (this.$refs.editAppointmentForm.validate()) {
        this.loading = true;

        this.$http({
          method: "patch",
          url: "appointment/" + this.editAppointmentFormData.id,
          data: {
            amountOfPeople: this.editAppointmentFormData.amountOfPeople,
            antigen: this.editAppointmentFormData.antigen,
            pcr: this.editAppointmentFormData.pcr,
            igm: this.editAppointmentFormData.igm,
            igg: this.editAppointmentFormData.igg,
            iggs: this.editAppointmentFormData.iggs,
            paidantigen: this.editAppointmentFormData.paidantigen,
            vac: this.editAppointmentFormData.vac,
            pcrintern: this.editAppointmentFormData.pcrintern,
          },
        })
          .then((res) => {
            this.$emit("editappointment", res.data);
            this.loading = false;
          })
          .catch((err) => {
            this.snackbarText = "Fehler bei Abfrage";
            this.snackbar = true;
            this.loading = false;
            console.log("AXIOS ERROR: ", err);
          });
      }
    },
  },
};
</script>
