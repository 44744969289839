<template>
  <v-card>
    <v-card-title>
      <span class="headline">Neue Termine anlegen</span>
    </v-card-title>

    <v-form ref="newAppointmentForm" v-model="newAppointmentForm" lazy-validation>
      <v-card-text>
        <v-row class="mt-n8">
          <v-col>
            <v-menu v-model="appointmentDatepicker" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="newAppointmentFormData.date"
                  label="Datum"
                  append-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :rules="[() => !!newAppointmentFormData.date || 'Dieses Feld wird benötigt!']"
                ></v-text-field>
              </template>
              <v-date-picker v-model="newAppointmentFormData.date" locale="de" first-day-of-week="1" @input="appointmentDatepicker = false"></v-date-picker>
            </v-menu>
          </v-col>
          <v-col>
            <v-text-field
              v-model="newAppointmentFormData.amountOfPeople"
              label="Anzahl Personen pro 10 Min"
              required
              :rules="[() => !!newAppointmentFormData.amountOfPeople || 'Dieses Feld wird benötigt!']"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-n8">
          <v-col sm="6">
            <v-text-field class="pt-6" v-model="newAppointmentFormData.startTime" label="Startzeit" required :rules="[() => !!newAppointmentFormData.startTime || 'Dieses Feld wird benötigt!']"></v-text-field>
          </v-col>
          <v-col>
            <v-text-field class="pt-6" v-model="newAppointmentFormData.endTime" label="Endzeit" required :rules="[() => !!newAppointmentFormData.endTime || 'Dieses Feld wird benötigt!']"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-checkbox v-model="newAppointmentFormData.antigen" label="Antigen"></v-checkbox>
          </v-col>
          <v-col>
            <v-checkbox v-model="newAppointmentFormData.pcr" label="PCR"></v-checkbox>
          </v-col>
          <v-col>
            <v-checkbox v-model="newAppointmentFormData.igm" label="IGM"></v-checkbox>
          </v-col>
          <v-col>
            <v-checkbox v-model="newAppointmentFormData.igg" label="IGG"></v-checkbox>
          </v-col>
          <v-col>
            <v-checkbox v-model="newAppointmentFormData.iggs" label="IGGs"></v-checkbox>
          </v-col>
          <v-col>
            <v-checkbox v-model="newAppointmentFormData.paidantigen" label="Antigen paid"></v-checkbox>
          </v-col>
          <v-col>
            <v-checkbox v-model="newAppointmentFormData.vac" label="Impfung"></v-checkbox>
          </v-col>
          <v-col>
            <v-checkbox v-model="newAppointmentFormData.pcrintern" label="PCR - Intern"></v-checkbox>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :disabled="!newAppointmentForm" :loading="loading" color="blue darken-1" text @click="saveAppointment">
          Speichern
        </v-btn>
      </v-card-actions>
      <v-snackbar v-model="snackbar" timeout="3000">
        {{ snackbarText }}

        <template v-slot:action="{ attrs }">
          <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">
            Schließen
          </v-btn>
        </template>
      </v-snackbar>
    </v-form>
  </v-card>
</template>

<script>
import dayjs from "dayjs";

export default {
  props: { organisationId: Number },
  data() {
    return {
      snackbar: false,
      snackbarText: "",
      newAppointmentForm: false,
      newAppointmentFormData: {},
      appointmentDatepicker: false,
      loading: false,
    };
  },

  methods: {
    saveAppointment() {
      let startDate = dayjs(this.newAppointmentFormData.date + " " + this.newAppointmentFormData.startTime);
      let endDate = dayjs(this.newAppointmentFormData.date + " " + this.newAppointmentFormData.endTime);

      if (this.$refs.newAppointmentForm.validate()) {
        this.loading = true;
        console.log(this.newAppointmentFormData);

        this.$http({
          method: "post",
          url: "appointment",
          data: {
            org: this.organisationId,
            amountOfPeople: this.newAppointmentFormData.amountOfPeople,
            startTime: startDate.valueOf(),
            endTime: endDate.valueOf(),
            antigen: this.newAppointmentFormData.antigen,
            pcr: this.newAppointmentFormData.pcr,
            igm: this.newAppointmentFormData.igm,
            igg: this.newAppointmentFormData.igg,
            iggs: this.newAppointmentFormData.iggs,
            paidantigen: this.newAppointmentFormData.paidantigen,
            vac: this.newAppointmentFormData.vac,
            pcrintern: this.newAppointmentFormData.pcrintern,
          },
        })
          .then((res) => {
            this.$emit("newappointment", res.data);
            this.$refs.newAppointmentForm.reset();
            this.loading = false;
          })
          .catch((err) => {
            this.snackbarText = "Fehler bei Abfrage";
            this.snackbar = true;
            this.loading = false;
            console.log("AXIOS ERROR: ", err);
          });
      }
    },
  },
};
</script>
